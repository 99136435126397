import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import PageTransition from "gatsby-plugin-page-transitions"

import { Nav, UnstyledList } from "../components/commons"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Bassin from "../images/bassin-des-lumieres.png"
import Tbm from "../images/tbm.png"
import Keolis from "../images/keolis.png"

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #E4A17F
  }

  ${Nav} a {
    color: #fff
  }

  ${Nav} img {
    filter: grayscale(1) invert(1);
  }
`

const Root = styled.div`
  display: flex;
  padding-top: 7.7rem;
  padding-left: 1.4rem;
`

const H1 = styled.h1`
  font-weight: 900;
  font-size: 125px;
  font-family: "Playfair Display", serif;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  margin-bottom: 0;
  line-height: 1.1;
  min-height: 1250px;

  span:first-child {
    align-self: flex-end;
  }
`

const H2 = styled.h2`
  font-family: "visionbold";
  text-transform: uppercase;
  font-size: 1.6rem;
  letter-spacing: 6.5px;

  small {
    text-transform: none;
    font-size: 1.1rem;
    letter-spacing: 4.2px;
  }
`

const H3 = styled.h3`
  font-family: "visionbold";
  text-transform: uppercase;
  font-size: 1.4rem;
  letter-spacing: 6px;
  margin-top: 2.8rem;
  margin-left: 5.2rem;
  margin-bottom: 0.5rem;
`

const Content = styled.div`
  color: #fff;
  margin-top: 6.2rem;
  border-left: 1px solid #fff;
  padding-left: 0.9rem;
  margin-bottom: 6.2rem;

  p,
  li {
    font-family: "visionlight";
    font-size: 16px;
    margin-left: 0.5rem;
    letter-spacing: 4px;
    line-height: 1.5;
    padding-right: 1rem;
  }

  blockquote {
    font-family: "visionbold";
    text-transform: uppercase;
    font-style: italic;
    text-align: center;
    font-size: 1rem;
    margin-top: 2rem;
    margin: 2rem 9.8rem;
    line-height: 1.35;
  }
`

const RefList = styled(UnstyledList)`
  margin-top: 2.5rem;
  margin-left: -5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

function AboutPage() {
  return (
    <PageTransition>
      <Layout>
        <SEO title="À propos" />
        <GlobalStyle />
        <Root>
          <header>
            <H1>
              <span>design graphique</span>
              <span>direction artistique</span>
            </H1>
          </header>
          <Content>
            <H2>
              DIRECTRICE ARTISTIQUE & concepteur designer graphique <br />
              <small>(2018 - Aujourd'hui)</small>
            </H2>
            <H3>Mon Profil</H3>
            <p>
              Femme de caractère, passionnée et animée, je parcours la vie en
              accumulant les projets et les expériences.
            </p>
            <blockquote>
              "J’offre un travail audacieux et intelligent, en gardant un état
              d’esprit libre et pétillant.""
            </blockquote>
            <p>
              Actuellement concepteur designer graphique, je travaille au sein
              du groupe Keolis Bordeaux Métropole.
            </p>

            <p>
              Mon attrait pour l’art et l’illustration m’ont permis d’intégrer
              l’univers de la création graphique. Mon approche marketing, issue
              de mes différentes années de formation, me permet d’analyser les
              différents projets qui me sont confiés et d’y joindre mon univers
              sensoriel.
            </p>
            <H3>Mon credo</H3>
            <blockquote>
              "Il faut savoir sortir du cadre tout en respectant les règles
              établies."
            </blockquote>
            <H3>Mes compétences</H3>
            <UnstyledList>
              <li>Direction artistique</li>
              <li>Branding</li>
              <li>Print</li>
              <li>IllustrationWebdesign</li>
              <li>Édition</li>
              <li>Gestion de projet</li>
            </UnstyledList>
            <H3>Mes références</H3>
            <RefList>
              <li>
                <img height={65} src={Bassin} />
              </li>
              <li>
                <img height={65} src={Keolis} />
              </li>
              <li>
                <img height={65} src={Tbm} />
              </li>
            </RefList>
          </Content>
        </Root>
      </Layout>
    </PageTransition>
  )
}

export default AboutPage
